import React from 'react'

import {
  VictoryBar,
  VictoryChart,
  VictoryAxis,
  VictoryStack,
  VictoryGroup,
  VictoryLabel,
  VictoryLine,
  VictoryScatter,
  VictoryContainer,
} from 'victory'

const dataBelow5000 = [
  {
    year: '',
    value: null,
    sort: 0,
  },
  {
    year: 2004,
    value: 1.33,
    sort: 1,
  },
  {
    year: '---',
    value: 1.03,
    sort: 2,
  },
  {
    year: 2012,
    value: 0.73,
    sort: 3,
  },
  {
    year: 2013,
    value: 0.69,
    sort: 4,
  },
  {
    year: 2014,
    value: 0.59,
    sort: 5,
  },
  {
    year: 2015,
    value: 0.54,
    sort: 6,
  },
  {
    year: 2016,
    value: 0.47,
    sort: 7,
  },
  {
    year: 2017,
    value: 0.48, // 0,484
    sort: 8,
  },
  {
    year: 2018,
    value: 0.44, // 0,436
    sort: 9,
  },
]

const dataOver5000 = [
  {
    year: '',
    value: null,
    sort: 0,
  },
  {
    year: 2004,
    value: 0.36,
    sort: 1,
  },
  {
    year: '---',
    value: 0.21,
    sort: 2,
  },
  {
    year: 2012,
    value: 0.06,
    sort: 3,
  },
  {
    year: 2013,
    value: 0.05,
    sort: 4,
  },
  {
    year: 2014,
    value: 0.06,
    sort: 5,
  },
  {
    year: 2015,
    value: 0.21,
    sort: 6,
  },
  {
    year: 2016,
    value: 0.06,
    sort: 7,
  },
  {
    year: 2017,
    value: 0.05, // 0,045
    sort: 8,
  },
  {
    year: 2018,
    value: 0.07, // 0,066
    sort: 9,
  },
]

const getDashedData = data => data.slice(1, 4)
const getLineData = data => data.slice(3)
const getDotsData = data => data.filter(({ sort }) => sort !== 2)

const graphsData = [
  {
    data: dataBelow5000,
    dashedData: getDashedData(dataBelow5000),
    lineData: getLineData(dataBelow5000),
    dotsData: getDotsData(dataBelow5000),
    color: '#0099fc',
  },
  {
    data: dataOver5000,
    dashedData: getDashedData(dataOver5000),
    lineData: getLineData(dataOver5000),
    dotsData: getDotsData(dataOver5000),
    color: '#20bf55',
  },
]

const axisStyle = {
  tickLabels: {
    fontSize: 8,
    fontWeight: 400,
    opacity: 0.5,
    fontFamily: '"Nunito Sans", sans-serif',
    fill: '#043e86',
    transform: 'translate(20px, 20px)',
  },
  axis: {
    fill: '#d9e1ec',
    opacity: '0.15',
    height: '1px',
  },
}

const labelsStyle = {
  fontSize: 6,
  fontWeight: 600,
  fontFamily: '"Nunito Sans", sans-serif',
  color: '#043e86',
}

const strokeWidth = 1.2

const QualityGraph = props => (
  <VictoryChart
    height="250"
    domainPadding={{ x: 20 }}
    containerComponent={<VictoryContainer style={{ touchAction: 'auto' }} />}
  >
    <VictoryAxis
      tickValues={dataBelow5000.map(({ sort }) => sort)}
      tickFormat={t => {
        const datum = dataBelow5000.find(({ sort }) => sort === t)
        return datum.year
      }}
      style={{
        ...axisStyle,
      }}
    />
    <VictoryAxis
      dependentAxis
      tickFormat={x => `${x.toFixed(2)} %`}
      tickCount={8}
      domain={[0, 1.4]}
      style={{
        ...axisStyle,
        tickLabels: {
          ...axisStyle.tickLabels,
          fontSize: 7,
        },
      }}
    />

    {graphsData.map((values, key) => (
      <VictoryGroup key={key}>
        <VictoryLine
          data={values.dashedData}
          x="sort"
          y="value"
          style={{
            data: {
              stroke: values.color,
              strokeWidth,
              strokeDasharray: [5, 5],
            },
          }}
        />
        <VictoryLine
          data={values.lineData}
          x="sort"
          y="value"
          style={{
            data: { stroke: values.color, strokeWidth },
          }}
        />
        <VictoryScatter
          data={values.dotsData}
          x="sort"
          y="value"
          size={3}
          labels={({ datum }) => datum.value && `${datum.value} %`}
          style={{
            data: {
              fill: '#ffffff',
              stroke: values.color,
              strokeWidth,
            },
            labels: labelsStyle,
          }}
        />
      </VictoryGroup>
    ))}
  </VictoryChart>
)

export default QualityGraph
