import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import { Title, Text, Row, Gap, Select, Col, Sup } from '..'
import Price from './Price'
import Amount from './Amount'
import Quality from './Quality'
import { up } from '../../lib/styles'

const GraphBox = styled.div`
  box-shadow: 0 10px 60px -10px rgba(4, 62, 134, 0.2);
  border-radius: 6px;
  background-color: #ffffff;
  width: 100%;
`
const Content = styled.div`
  padding: 0;
  ${up('tablet')} {
    /* padding: 0 20px 0; */
    margin-bottom: -20px;
    padding: 0 0 0 20px;
  }
`

const BoxTop = styled.div`
  min-height: 72px;
  border-radius: 6px 6px 0 0;
  background-color: #ecf8ff;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
`

const GraphTitle = styled(Title)`
  ${({ theme: { fonts } }) => css`
    font-size: 20px;
    font-weight: 700;
    line-height: 30px;
    font-family: ${fonts.nunito};
  `}
`

const ResponsiveWrapperOuter = styled.div`
  max-width: 100%;
  overflow: scroll;
  padding: 0 10px;
  ${up('mobile')} {
    overflow: auto;
  }
`
const ResponsiveWrapper = styled.div`
  min-height: 500px;
  min-height: auto;
  min-width: 700px;
`
const ResponsiveGraphWrapper = props => (
  <ResponsiveWrapperOuter>
    <ResponsiveWrapper {...props} />
  </ResponsiveWrapperOuter>
)

const LegendTitle = props => (
  <Text
    fontSize="14px"
    lineHeight="20px"
    fontWeight={700}
    textColor="textGray"
    {...props}
  />
)

const LegendItem = props => (
  <Text
    fontSize="14px"
    lineHeight="20px"
    fontWeight={400}
    textColor="textGray"
    {...props}
  />
)

const LegendRow = styled(Row).attrs(() => ({
  responsive: true,
}))`
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: -50px;
  padding: 0 20px;
  z-index: 100;
`

const LegendCircle = styled.div`
  ${({ color, opacity = 1 }) => css`
    width: 10px;
    height: 10px;
    border: 3px solid ${color};
    border-radius: 50%;
    opacity: ${opacity};
    flex-shrink: 0;
  `}
`

export const PriceGraph = props => {
  const [selectedRegionIndex, setSelectedRegionIndex] = useState(0)

  return (
    <>
      <Col width="100%" alignItems="center" style={{ zIndex: 600 }}>
        <Select.Regions
          value={selectedRegionIndex}
          onChange={setSelectedRegionIndex}
        />
      </Col>
      <Gap gap="40px" />
      <GraphBox style={{ zIndex: 100 }}>
        <BoxTop>
          <GraphTitle>
            Vývoj průměrné ceny za 1m
            <Sup>3 </Sup>
            vody
          </GraphTitle>
        </BoxTop>
        <Content>
          <LegendRow>
            <Row alignItems="baseline">
              <LegendTitle>Vybraný kraj:</LegendTitle>
              <Gap gap="20px" />
              <LegendCircle color="#0099fc" />
              <Gap gap="10px" />
              <LegendItem>Vodné</LegendItem>
              <Gap gap="40px" mobileGap="20px" />
              <LegendCircle color="#20bf55" />
              <Gap gap="10px" />
              <LegendItem>Stočné</LegendItem>
            </Row>
            <Gap gap="60px" showAfter="mobile" />
            <Row alignItems="baseline">
              <LegendTitle>Česká republika:</LegendTitle>
              <Gap gap="20px" />
              <LegendCircle color="#0099fc" opacity={0.25} />
              <Gap gap="10px" />
              <LegendItem>Vodné</LegendItem>
              <Gap gap="40px" mobileGap="20px" />
              <LegendCircle color="#20bf55" opacity={0.25} />
              <Gap gap="10px" />
              <LegendItem>Stočné</LegendItem>
            </Row>
          </LegendRow>
          <ResponsiveGraphWrapper>
            <Price regionIndex={selectedRegionIndex} />
          </ResponsiveGraphWrapper>
        </Content>
      </GraphBox>
    </>
  )
}

export const AmountGraph = props => (
  <>
    <GraphBox style={{ zIndex: 100 }}>
      <BoxTop>
        <GraphTitle>
          Odběry vod v mil. m<Sup>3 </Sup>
          (2017)
        </GraphTitle>
      </BoxTop>
      <Content>
        <LegendRow>
          <Row alignItems="baseline">
            <LegendCircle color="#0099fc" />
            <Gap gap="10px" />
            <LegendItem>Povrchová voda</LegendItem>
            <Gap gap="40px" />
            <LegendCircle color="#20bf55" />
            <Gap gap="10px" />
            <LegendItem>Podzemní voda</LegendItem>
          </Row>
        </LegendRow>
        <ResponsiveGraphWrapper>
          <Amount />
        </ResponsiveGraphWrapper>
      </Content>
    </GraphBox>
  </>
)

export const QualityGraph = props => (
  <>
    <GraphBox style={{ zIndex: 100 }}>
      <BoxTop>
        <GraphTitle>
          V kolika % kontrol došlo k překročení nejvyšší mezní hodnoty?
        </GraphTitle>
      </BoxTop>
      <Content>
        <LegendRow>
          <Row alignItems="baseline">
            <LegendCircle color="#0099fc" />
            <Gap gap="10px" />
            <LegendItem>Oblasti zásobující do 5 000 obyvatel</LegendItem>
            <Gap gap="40px" />
            <LegendCircle color="#20bf55" />
            <Gap gap="10px" />
            <LegendItem>Oblasti zásobující více než 5 000 obyvatel</LegendItem>
          </Row>
        </LegendRow>
        <ResponsiveGraphWrapper>
          <Quality />
        </ResponsiveGraphWrapper>
      </Content>
    </GraphBox>
  </>
)
