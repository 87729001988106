import React from 'react'

const Image = props => (
  <svg
    width="457"
    height="419"
    viewBox="0 0 457 419"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ maxWidth: '100%' }}
  >
    <path
      d="M317 419C394.32 419 457 355.506 457 277.182C457 198.858 317 29 317 29C317 29 177 198.858 177 277.182C177 355.506 239.68 419 317 419Z"
      fill="#0099FC"
      fillOpacity="0.25"
    />
    <text
      fill="#043E86"
      xmlSpace="preserve"
      // style="white-space: pre"
      fontFamily="VentiCF"
      fontSize="28"
      fontWeight="bold"
      letterSpacing="0px"
    >
      <tspan x="286.828" y="216.707">
        2017
      </tspan>
    </text>
    <text
      fill="#0099FC"
      xmlSpace="preserve"
      // style="white-space: pre"
      fontFamily="VentiCF"
      fontSize="54"
      fontWeight="bold"
      letterSpacing="0px"
    >
      <tspan x="248.362" y="309.721">
        97,5 %
      </tspan>
    </text>
    <path
      d="M121.5 332C188.603 332 243 277.949 243 211.273C243 144.597 121.5 0 121.5 0C121.5 0 0 144.597 0 211.273C0 277.949 54.3974 332 121.5 332Z"
      fill="#E5E5E5"
    />
    <text
      opacity="0.75"
      fill="#4A4A4A"
      xmlSpace="preserve"
      // style="white-space: pre"
      fontFamily="VentiCF"
      fontSize="28"
      fontWeight="bold"
      letterSpacing="0px"
    >
      <tspan x="92.8281" y="160.707">
        1989
      </tspan>
    </text>
    <text
      fill="#4A4A4A"
      xmlSpace="preserve"
      // style="white-space: pre"
      fontFamily="VentiCF"
      fontSize="54"
      fontWeight="bold"
      letterSpacing="0px"
    >
      <tspan x="55.3623" y="236.721">
        71,5 %
      </tspan>
    </text>
  </svg>
)

export default Image
