import React from 'react'
import styled from 'styled-components'

import {
  VictoryBar,
  VictoryChart,
  VictoryAxis,
  VictoryStack,
  VictoryGroup,
  VictoryLabel,
  VictoryZoomContainer,
  VictoryContainer,
} from 'victory'

import { regions, countryData } from '../../lib/config'

const axisStyle = {
  tickLabels: {
    fontSize: 10,
    fontWeight: 400,
    opacity: 0.5,
    fontFamily: '"Nunito Sans", sans-serif',
    fill: '#043e86',
    transform: 'translate(20px, 20px)',
  },
  axis: {
    fill: '#d9e1ec',
    opacity: '0.15',
    height: '1px',
  },
}

const barLabelsStyle = {
  fill: 'black',
  opacity: 0.5,
  fontSize: 7,
  fontFamily: '"Nunito Sans", sans-serif',
  fontWeight: 700,
}

const stackLabelsStyle = {
  fill: 'black',
  opacity: 1,
  fontSize: 8,
  fontWeight: 700,
  fontFamily: '"Nunito Sans", sans-serif',
}

const PriceGraph = ({ regionIndex = 0, ...props }) => {
  const regionData = regions[regionIndex].data

  return (
    <VictoryChart
      domainPadding={{ x: 30 }}
      containerComponent={<VictoryContainer style={{ touchAction: 'auto' }} />}
    >
      <VictoryAxis
        tickValues={countryData.map(({ year }) => year)}
        style={axisStyle}
      />
      <VictoryAxis
        dependentAxis
        tickFormat={x => `${x} Kč`}
        domain={{ y: [0, 100] }}
        tickCount={10}
        style={axisStyle}
      />

      <VictoryGroup
        offset={25}
        style={{
          data: { width: 22 },
        }}
        groupComponent={<g transform="translate(55,0)" />}
        // alignment="middle"
      >
        <VictoryStack>
          <VictoryBar
            data={regionData}
            x="year"
            y="invoicedPrice"
            style={{
              data: { fill: '#0099fc' },
              labels: barLabelsStyle,
            }}
            labelComponent={<VictoryLabel dy={30} />}
            labels={({ datum }) => datum.invoicedPrice.toFixed(1)}
          />
          <VictoryBar
            data={regionData}
            x="year"
            y="dischargedPrice"
            labelComponent={<VictoryLabel dy={30} />}
            style={{
              data: { fill: '#20bf55' },
              labels: barLabelsStyle,
            }}
            cornerRadius={2}
            labels={({ datum }) => datum.dischargedPrice.toFixed(1)}
          />
          <VictoryBar
            data={regionData}
            x="year"
            y="dummy"
            labelComponent={<VictoryLabel dy={-10} />}
            style={{
              labels: stackLabelsStyle,
            }}
            labels={({ datum }) => datum.sumPrice.toFixed(1)}
          />
        </VictoryStack>
        <VictoryStack>
          <VictoryBar
            data={countryData}
            x="year"
            y="invoicedPrice"
            labelComponent={<VictoryLabel dy={30} />}
            style={{
              data: { fill: '#0099fc', opacity: 0.25 },
              labels: barLabelsStyle,
            }}
            labels={({ datum }) => datum.invoicedPrice.toFixed(1)}
          />
          <VictoryBar
            data={countryData}
            x="year"
            y="dischargedPrice"
            labelComponent={<VictoryLabel dy={30} />}
            style={{
              data: { fill: '#20bf55', opacity: 0.25 },
              labels: barLabelsStyle,
            }}
            cornerRadius={2}
            labels={({ datum }) => datum.dischargedPrice.toFixed(1)}
          />
          <VictoryBar
            data={countryData}
            x="year"
            y="dummy"
            labelComponent={<VictoryLabel dy={-10} />}
            style={{
              labels: stackLabelsStyle,
            }}
            labels={({ datum }) => datum.sumPrice.toFixed(1)}
          />
        </VictoryStack>
      </VictoryGroup>
    </VictoryChart>
  )
}

export default PriceGraph
