import React from 'react'

import {
  VictoryBar,
  VictoryChart,
  VictoryAxis,
  VictoryStack,
  VictoryLabel,
  VictoryContainer,
  VictoryTooltip,
} from 'victory'

const data = [
  {
    type: 'Vodovody pro veřejnou\npotřebu',
    up: 320.6,
    down: 296.1,
    dummy: 0,
  },
  {
    type: 'Zemědělství ',
    up: 31.9,
    down: 14.7,
    dummy: 0,
    upTooltip: true,
    downTooltip: true,
  },
  {
    type: 'Energetika',
    up: 677.8,
    down: 2,
    dummy: 0,
    downTooltip: true,
  },
  {
    type: 'Průmysl ',
    up: 220,
    down: 37.3,
    dummy: 0,
    downTooltip: true,
  },
  {
    type: 'Ostatní vč. stavebnictví',
    up: 10.8,
    down: 19.3,
    dummy: 0,
    upTooltip: true,
    downTooltip: true,
  },
]

const axisStyle = {
  tickLabels: {
    fontSize: 10,
    fontWeight: 400,
    opacity: 0.5,
    fontFamily: '"Nunito Sans", sans-serif',
    fill: '#043e86',
    transform: 'translate(20px, 20px)',
  },
  axis: {
    fill: '#d9e1ec',
    opacity: '0.15',
    height: '1px',
  },
}

const barLabelsStyle = {
  fill: 'black',
  opacity: 0.5,
  fontSize: 7,
  fontFamily: '"Nunito Sans", sans-serif',
  fontWeight: 700,
}

class CustomLabel extends React.Component {
  render() {
    const { datum, isUp } = this.props

    const tooltipOnly = isUp ? datum.upTooltip : datum.downTooltip

    return (
      <g>
        {!tooltipOnly && <VictoryLabel {...this.props} dy={30} />}
        {tooltipOnly && <VictoryTooltip {...this.props} dy={2} />}
      </g>
    )
  }
}

CustomLabel.defaultEvents = VictoryTooltip.defaultEvents

const PriceGraph = props => (
  <VictoryChart
    domainPadding={{ x: 50 }}
    height="250"
    containerComponent={<VictoryContainer style={{ touchAction: 'auto' }} />}
  >
    <VictoryAxis
      tickValues={data.map(({ type }) => type)}
      style={{
        ...axisStyle,
        tickLabels: {
          ...axisStyle.tickLabels,
          fontSize: 7,
        },
      }}
    />
    <VictoryAxis
      dependentAxis
      tickFormat={x => `${x} mil.`}
      domain={{ y: [0, 700] }}
      tickCount={7}
      style={axisStyle}
    />

    <VictoryStack>
      <VictoryBar
        data={data}
        x="type"
        y="down"
        style={{
          data: { fill: '#0099fc' },
          labels: barLabelsStyle,
        }}
        labelComponent={<CustomLabel />}
        labels={({ datum }) => datum.down}
      />
      <VictoryBar
        data={data}
        x="type"
        y="up"
        labelComponent={<CustomLabel isUp />}
        style={{
          data: { fill: '#20bf55' },
          labels: barLabelsStyle,
        }}
        cornerRadius={2}
        labels={({ datum }) => datum.up}
      />
    </VictoryStack>
  </VictoryChart>
)

export default PriceGraph
