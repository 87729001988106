import React, { Fragment } from 'react'
import styled, { css } from 'styled-components'
import {
  Row,
  Col,
  Gap,
  Text,
  SEO,
  Title,
  Container,
  Box,
  PathSection,
  FaqSection,
  ArticlesSection,
  Sup,
  Tooltip,
  TapsBg,
} from '../components'
import { AmountGraph } from '../components/graphs'
import DropsTubesImage from '../components/images/DropsTubes'

import worldIconSrc from '../data/images/icons/world.svg'
import percentIconSrc from '../data/images/icons/percent.svg'
import Year1989IconSrc from '../data/images/icons/1989.svg'
import TubesSrc from '../data/images/tubes.svg'

const czechData = [
  {
    title: 'Délka vodovodní sítě v České republice',
    amount: '78 750 km',
    icon: worldIconSrc,
    info: 'Odpovídá délce dvou zemských rovníků',
  },
  {
    title: 'Průměrná denní spotřeba na osobu',
    amount: '89 litrů',
    icon: Year1989IconSrc,
    info: 'V roce 1989 byla spotřeba dvojnásobná',
  },
  {
    title: 'Denní ztráta vody ve vodohospodářské síti na osobu',
    amount: '27 litrů',
    icon: percentIconSrc,
    info: 'Představuje 15,8 % z vody vyrobené určené k realizaci',
  },
]

const TubesImageWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: -10;
  img {
    max-width: 100%;
  }
`

const TUBES_IMG_BP = 500

class Page extends React.Component {
  render() {
    const {
      location: { pathname },
    } = this.props

    return (
      <>
        <SEO pathname={pathname} title="Cesta vody" />
        <Gap.Top />
        <Container>
          <Col width="100%" justifyContent="center" alignItems="center">
            <Title.Main>Cesta vody</Title.Main>
          </Col>
          <Gap gap="185px" />
          <Col width="100%" alignItems="center">
            <Title textAlign="center">
              Čím vším musí voda projít, než se dostane do vašeho kohoutku?
            </Title>
          </Col>

          <Gap gap="80px" mobileGap="80px" bp={1100} />
          <PathSection />
        </Container>

        <Gap gap="170px" mobileGap="60px" bp={900} />

        <Col width="100%" alignItems="center" position="relative">
          <TapsBg
            transparent
            right="0"
            top="5px"
            topMobile="180px"
            xCount={4}
            yCount={2}
            xCountMobile={2}
            yCountMobile={6}
          />
          <Container>
            <Col
              width="100%"
              alignItems="center"
              mobileAlignItems="center"
              bp="tablet"
            >
              <Title textAlign="center">Česko v číslech</Title>
              <Gap gap="40px" />
              <Row
                width="100%"
                justifyContent="space-between"
                mobileAlignItems="center"
                alignItems="stretch"
                responsive
                bp="tablet"
              >
                {czechData.map(({ title, amount, icon, info }, key) => (
                  <Fragment key={key}>
                    <Box padding="0">
                      <Col justifyContent="space-between" height="100%">
                        <Col
                          padding="40px 40px 0"
                          mobilePadding="40px 30px 0"
                          bp={1200}
                        >
                          <Text.HighLight>{title}</Text.HighLight>
                          <Gap gap="52px" />
                          <Title.DataAmount
                            fontSize="50px"
                            mobileFontSize="36px"
                            mobileLineHeight="42px"
                            bp={400}
                          >
                            {amount}
                          </Title.DataAmount>
                          <Gap gap="35px" />
                        </Col>
                        <Box.Bottom>
                          <Row width="100%">
                            <img src={icon} alt="Ikona světa" />
                            <Gap gap="20px" />
                            <Text.DataDescriptionLarge fontWeight="400">
                              {info}
                            </Text.DataDescriptionLarge>
                          </Row>
                        </Box.Bottom>
                      </Col>
                    </Box>
                    {key + 1 !== czechData.length && (
                      <Gap gap="20px" mobileGap="40px" bp="tablet" />
                    )}
                  </Fragment>
                ))}
              </Row>
              <Gap gap="120px" mobileGap="80px" bp="tablet" />
              <Title.Sub>Odběr surové vody</Title.Sub>
              <Gap.Title />
              <Text maxWidth="780px" textAlign="center">
                Ročně se v České republice odebere více než 1,6 miliardy m
                <Sup>3</Sup>. Takové množství by zaplnilo 2 nádrže Orlík a ještě
                by třetina zbyla. Vůbec nejvíce vody se využívá v energetice,
                podobně vysoké množství jde i do vodovodních rozvodů.
              </Text>
              <Gap gap="60px" />
              <AmountGraph />
            </Col>
          </Container>
        </Col>

        <Container>
          <Gap gap="160px" mobileGap="80px" bp="tablet" />

          <Col
            width="100%"
            alignItems="stretch"
            mobileAlignItems="center"
            bp="tablet"
          >
            <Row justifyContent="space-between" responsive bp="tablet">
              <Col.Text>
                <Title.Sub>Kam voda mizí aneb Ztráty</Title.Sub>
                <Gap.Title />
                <Text>
                  Předtím, než si natočíte vodu z kohoutku, urazí dlouhou cestu
                  potrubím – může se jednat o kilometry i desítky kilometrů. Až
                  15,8 % celkového objemu vody ze sítě odteče kvůli stavu
                  potrubí a nárazovým únikům.
                </Text>
                <Gap.Paragraph />
                <Text>
                  Celkové ztráty vody v roce 2018 představovaly téměř 95 miliard
                  litrů. Denně se tedy z vodovodní sítě ztratilo 260 150 685
                  litrů. To je 10 % Černého jezera nebo 2,6 milionu napuštěných
                  van.
                </Text>
                <Gap.Paragraph />
                <Text>
                  Zní to děsivě, že? Těžko se věří, že byly ročně výrazně vyšší
                  – šplhaly se až ke 300 miliardám litrů ročně. Potrubí se ale
                  průběžně opravuje a kontroluje a také jsme zrychlili reakce na
                  úniky. Za toto šetření vodou vděčíme i novým technologiím.
                  Ztráty v České republice tak patří k vůbec nejnižším.
                </Text>
              </Col.Text>

              <Gap gap="90px" mobileGap="60px" bp={TUBES_IMG_BP} />

              <Col
                grow={1}
                justifyContent="center"
                alignItems="center"
                position="relative"
              >
                <TubesImageWrapper>
                  <img src={TubesSrc} alt="" />
                </TubesImageWrapper>

                <Gap gap="130px" mobileGap="80px" bp={TUBES_IMG_BP} />

                <Title.DataAmountLarge>95</Title.DataAmountLarge>
                <Text.HighLight textAlign="center" maxWidth="204px">
                  milionů m<Sup>3</Sup> vody ztraceno ročně (2018)
                </Text.HighLight>
              </Col>
            </Row>

            <Gap gap="170px" mobileGap="100px" bp={TUBES_IMG_BP} />

            <Row
              justifyContent="space-between"
              mobileFlexDirection="column-reverse"
              responsive
              bp="tablet"
            >
              <Col grow={1} alignItems="center" justifyContent="flex-start">
                <Text.HighLight maxWidth="290px" textAlign="center">
                  Podíl čištění odpadních vod v České republice
                </Text.HighLight>
                <Gap gap="25px" />
                <DropsTubesImage />
              </Col>
              <Gap gap="0" mobileGap="60px" bp="tablet" />
              <Col.Text>
                <Title.Sub>Vypouštění odpadních vod</Title.Sub>
                <Gap.Title />
                <Text>
                  Do kanalizace bylo v roce 2017 vypuštěno celkem 524,2 milionů
                  m<Sup>3</Sup> – přímo do povrchových toků se pak dostalo 1 692
                  milionů m<Sup>3</Sup> (více než trojnásobek množství
                  odvedeného do kanalizace). Za toto znečišťování jsou stanoveny{' '}
                  <Tooltip content="Poplatky se skládají z objemu vypuštěné vody a množství znečištění v ní.">
                    poplatky
                  </Tooltip>
                  , které platí fyzické i právnické osoby, které za poplatkové
                  období vypustily více než 100 000 m<Sup>3</Sup>.
                </Text>
              </Col.Text>
            </Row>
          </Col>
        </Container>

        <Gap gap="175px" mobileGap="80px" bp="tablet" />

        <ArticlesSection page="PATH" />

        <FaqSection page="PATH" />

        <Gap gap="230px" />
      </>
    )
  }
}

export default Page
